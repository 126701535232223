<template>
  <v-snackbar bottom left multi-line :timeout="-1" v-model="snackbar" color="secondary" >
    <p>This site uses cookies. By continuing to browse the site you are agreeing to our use of cookies. Check our <a v-on:click.stop="$store.state.private_elements.showPrivacyDialog = true">Privacy Policy</a>.</p>
    <v-btn class="blue--text darken-1" text @click.native="acceptCookies">I Agree</v-btn>
  </v-snackbar>
</template>

<script>
export default {
  data (context) {
    return {
      snackbar: this.$cookie.get('cookieAccepted') !== 'y'
    }
  },
  methods: {
    acceptCookies: function () {
      this.$cookie.set('cookieAccepted', 'y', { expires: '2Y' })
      this.snackbar = false
    }
  }
}
</script>

<style>
div.v-snack:not(.v-snack--absolute) {
    height: 100%;
}
</style>
